

import { mapMutations, mapGetters, mapState } from 'vuex'
import { BIconDownload } from 'bootstrap-vue'
import CCEmail from '~/components/CCEmail.vue'
import Tax from '~/components/Tax.vue'
import PaymentAll from '~/components/PaymentMethod/PaymentAll.vue'
import SCGPConsent from '~/components/SCGP/SCGPConsent.vue'
// import QRCode from '~/components/PaymentMethod/QRCodeCollapse.vue'
// import QRCS from '~/components/PaymentMethod/QRCSCollapse.vue'
// import SCB from '~/components/PaymentMethod/SCBCollapse.vue'
// import Alipay from '~/components/PaymentMethod/Alipay.vue'
// import WeChatPay from '~/components/PaymentMethod/WeChatPay.vue'
const cfg = require(`~/configs/${process.env.NODE_ENV}.json`)
export default {
  middleware: ['auth'],
  components: {
    BIconDownload,
    CCEmail,
    Tax,
    PaymentAll,
    SCGPConsent
    // QRCode,
    // QRCS,
    // SCB
    // Alipay,
    // WeChatPay
  },
  validate ({ params }) {
    // Must be a number
    return /^\d+$/.test(params.id)
  },
  async asyncData ({ params, $axios, $cookies, redirect, query, store }) {
    try {
      const result = await $axios.$get(`orders/${params.id}`)
      const res = await $axios.get('me')
      const userProfile = res.data.user ? res.data.user : {}
      const provinceData = await $axios.$get('provinces')
      let resultMapping = null
      if (result.data && typeof result.data.products !== 'undefined') {
        resultMapping = await $axios.$get('mapping_inputs', {
          params: { mapping_pattern_id: result.data.products[0].mapping_input_id }
        })
      }

      if (result.data.payment_type > 0) {
        redirect('/my/orders/' + params.id)
      }
      //   console.log('*** checkoutData ***', result.data)
      return {
        orderID: params.id,
        order: result.data,
        totalResult: result.data,
        detail: [],
        packagingTypeId: (resultMapping != null && result.data.products[0].mapping_input_id) ? resultMapping.data.mappingInput.packaging_type_id : 4,
        provinceData: provinceData.data,
        dimension_img: (resultMapping != null) ? resultMapping.data.dimension_img : '',
        default_img: (resultMapping != null) ? resultMapping.data.default_img : '',
        userProfile,
        mappingInputObject: (resultMapping != null) ? resultMapping.data : null,
        alertMsg: ''
      }
    } catch (e) {
      console.log('error', e)

      if (e.response.status === 404) {
        redirect('/error/404')
      }
      redirect('/')
    }
  },
  data () {
    return {
      totalService: 0,
      totalDiscounTaxService: 0,
      selectedCreditType: null,
      selectedPaymentMethod: null,
      collapses: {
        qrcode: false,
        qrcs: false,
        scb: false
      },
      secondPhoneNumber: '',
      apiBaseURL: cfg.axios.apiBaseURL,
      isSending: false,
      totalResult: {},
      detail: '',
      provinceData: [],
      dimension_img: '',
      default_img: '',
      textAr: '',
      contactName: '',
      phoneNumber: '',
      address: '',
      province: '',
      zipcode: '',
      taxSelected: 0,
      sameAddressSelected: 0,
      cardPaperActiveName: '',
      taxList: [
        { text: 'ต้องการ', value: 1 },
        { text: 'ไม่ต้องการ', value: 0 }
      ],
      sameAddress: [
        { text: 'ใช้ที่อยู่เดียวกับที่อยู่จัดส่ง', value: 1 },
        { text: 'กำหนดเอง', value: 0 }
      ],
      colorText: {
        1: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        2: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        },
        3: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 1 สี',
          4: 'พิมพ์ 4 สี เสมือนจริง'
        },
        5: {
          0: 'ไม่พิมพ์สี',
          1: 'พิมพ์ 4 สี เสมือนจริง'
        }
      },
      taxPersonaSelected: 'company',
      taxPersona: [
        { text: 'นิติบุคคล', value: 'company' },
        { text: 'บุคคลธรรมดา', value: 'sme' }
      ],
      productData: {
        product_type: '',
        product_type_other: '',
        product_title: ''
      },
      productTypes: [
        { text: 'เลือกประเภทสินค้า', value: '' },
        { text: 'ของเล่น', value: 'toy' },
        { text: 'ผ้า', value: 'cloth' },
        { text: 'เครื่องสำอาง', value: 'cosmetic' },
        { text: 'อาหาร', value: 'food' },
        { text: 'อิเล็กทรอนิกส์', value: 'electronic' },
        { text: 'บ้าน', value: 'home' },
        { text: 'ผลไม้', value: 'fruit' },
        { text: 'ของฝาก/กิ๊ฟ', value: 'souvenir/giff' },
        { text: 'อื่นๆ', value: 'other' }
      ],
      tax: {
        tax_number: '',
        company_name: '',
        phoneNumber: '',
        address: '',
        subdistrict: '',
        district: '',
        province: '',
        zipcode: ''
      },
      paymentSelected: 2,
      cardNumber: '',
      ExpirationDateMonth: '',
      ExpirationDateYear: '',
      CVV: '',
      imageOrder: require('~/static/images/select-products/order1-1.jpg'),
      visa: require('~/static/images/location-payment/visa.jpg'),
      master: require('~/static/images/location-payment/master.jpg'),
      scb: require('~/static/images/location-payment/scb.jpg'),
      btnSaveAddress: false,
      emailBanking: '',
      stickyTop: 0
    }
  },
  computed: {
    ...mapState(['userData', 'purposes']),
    ...mapGetters(['getToken']),
    // discounTaxService () {
    //   if (this.taxPersonaSelected === 'company') {
    //     return this.$store.state.aws_s3
    //   }
    //   return null
    // }
    stickyStyle () {
      return {
        position: 'sticky',
        top: `calc(6.5rem + ${this.stickyTop}px)`
      }
    }
  },
  watch: {
    selectedPaymentMethod (newValue, oldValue) {
      Object.keys(this.collapses).forEach((key) => {
        this.collapses[key] = false
      })
      // Set the selected collapse state to true
      this.collapses[newValue.toLowerCase()] = true
    },
    async taxSelected (newVal, oldVal) {
      if (newVal === 0) {
        this.tax = {
          tax_number: '',
          company_name: '',
          phoneNumber: '',
          address: '',
          subdistrict: '',
          district: '',
          province: '',
          zipcode: ''
        }
        this.showLoading()
        const postData = {
          require_tax_invoice: this.taxSelected,
          tax_id: this.tax.tax_number,
          company: this.tax.company_name,
          invoice_phone: this.tax.phoneNumber,
          invoice_address: this.tax.address,
          invoice_province_id: this.province,
          invoice_zipcode: this.zipcode
        }
        const result = await this.$axios.$post(`order/${this.orderID}/save-invoice-address`, postData)
        if (result.success === true) {
        }
        this.hideLoading()
      }
    }
  },
  created () {
    this.getActivePurposes()
  },
  mounted () {
    try {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      window.dataLayer.push({
        event: 'pageInfo',
        page: {
          type: 'cart',
          path: `${this.$route.path}`,
          title: 'checkout'
        },
        user: {
          customerID: (this.userData) ? this.userData.uid : '',
          customerEmail: (this.userData) ? this.userData.email : ''
        }
      })

      if (this.userProfile) {
        this.contactName = this.totalResult.name ? this.totalResult.name : this.userProfile.name
        this.phoneNumber = this.totalResult.phone ? this.totalResult.phone : this.userProfile.phone
      } else {
        this.contactName = this.totalResult.name
        this.phoneNumber = this.totalResult.phone
      }
      if (this.totalResult.secondary_phone) {
        this.secondPhoneNumber = this.totalResult.secondary_phone
      }
      this.address = this.totalResult.address
      this.province = this.totalResult.province_id
      this.zipcode = this.totalResult.zipcode
      this.changeZipcode(this.zipcode, 'address')
      if (
        (this.totalResult.name && this.totalResult.name.length > 0) &&
        (this.totalResult.address && this.totalResult.address.length > 0) &&
        (this.totalResult.phone && this.totalResult.phone.length > 0) &&
        (this.totalResult.zipcode && this.totalResult.zipcode.length > 0)
      ) {
        this.btnSaveAddress = true
      }
      this.taxSelected = 1
      // this.taxSelected = (this.totalResult.require_tax_invoice === null ? 0 : this.totalResult.require_tax_invoice)
      this.tax.tax_number = this.totalResult.tax_id
      this.tax.company_name = this.totalResult.company
      this.tax.address = this.totalResult.invoice_address ? this.totalResult.invoice_address : this.totalResult.address
      this.tax.phoneNumber = this.totalResult.invoice_phone ? this.totalResult.invoice_phone : this.totalResult.phone

      this.tax.province = this.totalResult.invoice_province_id ? this.totalResult.invoice_province_id : this.province
      this.tax.zipcode = this.totalResult.invoice_zipcode ? this.totalResult.invoice_zipcode : this.zipcode
      this.changeZipcode(this.tax.zipcode, 'tax')
      this.sendDataLayer('checkout')
      if (!this.tax.phoneNumber && this.userProfile && this.userProfile.phone) {
        this.tax.phoneNumber = this.userProfile.phone
      }

      if ('fail' in this.$route.query) {
        const reason = parseInt(this.$route.query.fail)
        if (reason === 1) {
          this.setAlertWithLine({
            show: true,
            message: 'เกิดข้อขัดข้องในการชำระเงิน',
            header: 'แจ้งเตือน'
          })
        } else if (reason === 2) {
          this.setAlertWithLine({
            show: true,
            message: 'รายการได้ทำการชำระเรียบร้อยแล้ว',
            header: 'แจ้งเตือน'
          })
        } else if (reason === 3) {
          this.setAlertWithLine({
            show: true,
            message: 'User Token is not valid',
            header: 'แจ้งเตือน'
          })
        } else if (reason === 4) {
          this.setAlertWithLine({
            show: true,
            message: '',
            header: 'แจ้งเตือน'
          })
        }
      }
      localStorage.removeItem('click-login-from-page-query')
      localStorage.removeItem('click-login-from-page-params')
      localStorage.removeItem('click-login-from-page')
      this.hideLoading()
    } catch (e) {
      console.log('errrrr', e.message)
    }

    // console.log('.....', this.totalResult)
    // if (this.totalResult.tax_type === 'company') {
    //   console.log('>>> checkout totalResult1 <<<', this.totalResult.design_price, this.totalResult.print_plate_price, this.totalResult.plate_diecut_price)
    //   const designPrice = this.$roundToDecimals(this.$roundToDecimals(this.$calculateExcludeVat(this.totalResult.design_price), 3), 2)
    //   const printPlatePrice = this.$roundToDecimals(this.$roundToDecimals(this.$calculateExcludeVat(this.totalResult.print_plate_price), 3), 2)
    //   const plateDiecutPrice = this.$roundToDecimals(this.$roundToDecimals(this.$calculateExcludeVat(this.totalResult.plate_diecut_price), 3), 2)
    //   console.log('>>> checkout totalResult2 <<<', designPrice, printPlatePrice, plateDiecutPrice)

    //   this.totalDiscounTaxService = this.$calculateExcludeVatService(designPrice + printPlatePrice + plateDiecutPrice)
    //   this.totalResult.total = this.totalResult.total - this.totalDiscounTaxService
    // }
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading', 'setAlert', 'setAlertWithLine', 'getActivePurposes']),
    async changeZipcode (value, type) {
      const result = await this.$axios.$get('zipcode/get/province', { params: { zipcode: value } })
      if (result.status === true) {
        if (type === 'address') {
          this.province = result.data.province_id
        } else {
          this.tax.province = result.data.province_id
        }
      }
    },
    calCostPerPcs2C2P (totalCost, amt) {
      return Math.round(((totalCost * 0.03) / amt) * 100) / 100// =ROUND((totalCost*0.03)/amt,2);
    },
    calFeeFor2C2P (totalCost, amt) {
      const costPerPcs = this.calCostPerPcs2C2P(totalCost, amt)
      return costPerPcs * amt
    },
    calCostFor2C2P (totalResult) {
      const totalCost = totalResult.total
      const amt = totalResult.products[0].amount
      const sumPrice = ('sum_price' in totalResult) ? totalResult.sum_price : totalCost
      const discount = ('promotion' in totalResult && totalResult.promotion) ? totalResult.promotion.discount : 0
      const costPerPcs = this.calCostPerPcs2C2P((sumPrice - discount), amt)
      const fee = costPerPcs * amt
      // console.log(amt, costPerPcs, fee, totalCost, totalCost + fee)
      return Number(totalCost) + Number(fee)
    },
    creditCardType (number) {
      // visa
      let re = new RegExp('^4')
      if (number.match(re) != null) { return 'Visa' }

      // Mastercard
      // Updated for Mastercard 2017 BINs expansion
      if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(number)) { return 'Mastercard' }

      // AMEX
      re = new RegExp('^3[47]')
      if (number.match(re) != null) { return 'AMEX' }

      // Discover
      re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)')
      if (number.match(re) != null) { return 'Discover' }

      // Diners
      re = new RegExp('^36')
      if (number.match(re) != null) { return 'Diners' }

      // Diners - Carte Blanche
      re = new RegExp('^30[0-5]')
      if (number.match(re) != null) { return 'Diners - Carte Blanche' }

      // JCB
      re = new RegExp('^35(2[89]|[3-8][0-9])')
      if (number.match(re) != null) { return 'JCB' }

      // Visa Electron
      re = new RegExp('^(4026|417500|4508|4844|491(3|7))')
      if (number.match(re) != null) { return 'Visa Electron' }

      return ''
    },
    async saveAddress (showALert) {
      if (
        !this.contactName ||
        !this.phoneNumber ||
        !this.address ||
        !this.province ||
        !this.zipcode
      ) {
        this.setAlert({
          show: true,
          message: 'โปรดกรอกข้อมูลที่อยู่ให้ครบถ้วน',
          header: 'แจ้งเตือน'
        })
        return
      }
      this.showLoading()
      const postData = {
        name: this.contactName,
        phone: this.phoneNumber,
        secondary_phone: this.secondPhoneNumber,
        address: this.address,
        province_id: this.province,
        zipcode: this.zipcode
      }
      const result = await this.$axios.$post(`order/${this.orderID}/save-address`, postData)
      if (result.success === true) {
        this.$refs.refTax.getOrderData()
        this.btnSaveAddress = true
        this.alertMsg = result.message
        if (showALert) {
          this.$bvModal.show('modal-show-alert')
        }
      }
      this.hideLoading()
    },
    changeProductType () {
      this.productData.product_type_other = ''
    },
    async saveProductData () {
      this.showLoading()
      if (this.productData.product_type === '' || this.productData.product_title === '') {
        this.setAlert({
          show: true,
          message: 'กรุณากรอกข้อมูลสินค้าให้ครบถ้วน',
          header: 'แจ้งเตือน'
        })
        this.hideLoading()
        return
      }
      if (this.productData.product_type === 'other' && this.productData.product_type_other === '') {
        this.setAlert({
          show: true,
          message: 'กรุณากรอกข้อมูลอื่นๆให้ครบถ้วน',
          header: 'แจ้งเตือน'
        })
        this.hideLoading()
        return
      }
      const result = await this.$axios.$post(`order/${this.orderID}/save-optional`, this.productData)
      if (result.success === true) {
        this.alertMsg = 'บันทึกข้อมูลสินค้าเรียบร้อยแล้ว'
        this.$bvModal.show('modal-show-alert')
      }
      this.hideLoading()
    },
    async saveInvoice () {
      this.showLoading()
      const postData = {
        require_tax_invoice: this.taxSelected,
        tax_type: this.taxPersonaSelected,
        persona: this.taxPersonaSelected,
        tax_id: this.tax.tax_number,
        company: this.tax.company_name,
        tax_fullname: this.tax.fullname,
        invoice_phone: this.tax.phoneNumber,
        invoice_address: this.tax.address,
        invoice_province_id: this.tax.province,
        invoice_zipcode: this.zipcode
      }
      try {
        const result = await this.$axios.$post(`order/${this.orderID}/save-invoice-address`, postData)
        if (result.success === true) {
          alert(result.message)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err)
      }
      this.hideLoading()
    },
    async paymentIPP () {
      this.showLoading()
      const result = await this.$axios.$post(`2c2p-ipp-getlink/${this.orderID}`)
      if (result.success === true) {
        window.location = result.data.webPaymentUrl
      } else {
        this.setAlert({
          show: true,
          message: result.msg,
          header: 'แจ้งเตือน'
        })
      }
      this.hideLoading()
    },
    validateBeforePay () {
      if (
        this.btnSaveAddress === false
      ) {
        this.setAlert({
          show: true,
          message: 'โปรดบันทึกข้อมูลที่อยู่',
          header: 'แจ้งเตือน'
        })
        return false
      }
      return true
    },
    async payment (paidStatus, selectedPaymentMethod) {
      if (
        this.btnSaveAddress === false
      ) {
        this.setAlert({
          show: true,
          message: 'โปรดบันทึกข้อมูลที่อยู่',
          header: 'แจ้งเตือน'
        })
        return
      }
      await this.saveAddress(false)
      await this.$refs.refTax.saveInvoice(false)

      this.showLoading()

      if (paidStatus) {
        this.$router.push(this.localeLocation({ name: 'my-orders-orderId', params: { orderId: this.orderID } }))
        this.sendDataLayer('add_payment_info', selectedPaymentMethod)
      }
    },
    error (result) {
      if (result.error === 'verifyEmail') {
        this.hideLoading()
        this.$refs.emailModal.show = true
        return false
      }
    },
    async paymentOld () {
      if (
        this.btnSaveAddress === false
      ) {
        this.setAlert({
          show: true,
          message: 'โปรดบันทึกข้อมูลที่อยู่',
          header: 'แจ้งเตือน'
        })
        return
      }
      await this.saveAddress(false)
      if (this.$refs.refTax.saveTax === false) {
        await this.$refs.refTax.saveInvoice(false)
      }

      this.showLoading()

      // this.$ga.event('PurchaseFlow', 'Click', 'PaymentComplete-' + this.paymentSelected)
      if (parseInt(this.paymentSelected) === 1) {
        this.$refs.amount.value = this.$formatPriceFloat(this.calCostFor2C2P(this.totalResult))
        // this.$refs.amount.value = this.$formatPriceFloat(this.totalResult.total * 1.03)
        // console.log('----', this.CVV, this.ExpirationDateMonth, this.ExpirationDateYear, this.cardNumber)
        window.My2c2p.getEncrypted('2c2p-payment-form', (encryptedData, errCode, errDesc) => {
          // console.log('--- 2c2p --->', encryptedData, errCode)
          if (errCode !== 0) {
            alert(errDesc + ' (' + errCode + ')')
            this.hideLoading()
          } else {
            // const form = document.getElementById('2c2p-payment-form')
            this.$refs.orderID.value = this.orderID
            this.$refs.encryptedCardInfo.value = encryptedData.encryptedCardInfo
            this.$refs.maskedCardInfo.value = encryptedData.maskedCardInfo
            this.$refs.expMonthCardInfo.value = encryptedData.expMonthCardInfo
            this.$refs.expYearCardInfo.value = encryptedData.expYearCardInfo
            this.$refs.typeCreditCard.value = this.creditCardType(this.cardNumber)
            // form.submit()
          }
        })
      } else if (parseInt(this.paymentSelected) === 2) {
        const result = await this.$axios.$post(`order/${this.orderID}/order-email/${this.paymentSelected}`)
        if (result.success === true) {
          this.$router.push(this.localeLocation({ name: 'checkout-bank-transfer-finished-id', params: { id: this.orderID } }))
        } else {
          alert(result)
          this.hideLoading()
        }
      } else if (parseInt(this.paymentSelected) === 3) {
        if (this.emailBanking.length === 0) {
          alert('โปรดระบุ Email')
          return
        }
        this.$refs.amount_onlinebanking.value = this.$formatPriceFloat(this.calCostFor2C2P(this.totalResult))
        // this.$refs.amount_onlinebanking.value = this.$formatPriceFloat(this.totalResult.total * 1.03)
        window.My2c2p.getEncrypted('online-payment-form', (encryptedData, errCode, errDesc) => {
          if (errCode !== 0) {
            alert(errDesc + ' (' + errCode + ')')
            this.hideLoading()
          } else {
            const form = document.getElementById('online-payment-form')
            this.$refs.bankId.value = 'SCB'
            this.$refs.orderID_onlinebanking.value = this.orderID
            form.submit()
          }
        })
      } else if (parseInt(this.paymentSelected) === 5) { // qrcode
        this.hideLoading()
        // const result = await this.$axios.$post(`order/${this.orderID}/order-email/${this.paymentSelected}`)
        // if (result.success === true) {
        this.$router.push(this.localeLocation({ name: 'checkout-qrcode-id', params: { id: this.orderID } }))
        // } else {
        //   alert(result)
        //   this.hideLoading()
        // }
      } else if (parseInt(this.paymentSelected) === 6) { // scb debit direct
        this.hideLoading()
        this.$router.push(this.localeLocation({ name: 'checkout-debit-direct-id', params: { id: this.orderID } }))
      }
    },
    quotation () {
      if (
        this.btnSaveAddress === false
      ) {
        this.setAlert({
          show: true,
          message: 'โปรดบันทึกข้อมูลที่อยู่',
          header: 'แจ้งเตือน'
        })
        return
      }
      const smeData = JSON.parse(this.totalResult.products[0].sme_json)
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ ecommerce: null })
      if (smeData && smeData.mapping_inputs) {
        window.dataLayer.push({
          event: 'getQuotation',
          productName: smeData.mapping_inputs.name_th,
          supplier: (typeof this.totalResult.products[0] !== 'undefined' ? 'โรงงาน ' + this.totalResult.products[0].factory_id : ''),
          value: parseFloat(this.totalResult.total).toFixed(2)
        })
      } else if (this.totalResult.products[0].rigid) {
        window.dataLayer.push({
          event: 'getQuotation',
          productName: this.totalResult.products[0].rigid.name,
          supplier: (typeof this.totalResult.products[0] !== 'undefined' ? 'โรงงาน ' + this.totalResult.products[0].factory_id : ''),
          value: parseFloat(this.totalResult.total).toFixed(2)
        })
      }
      this.$axios({
        url: this.$baseurl(`order/${this.orderID}/quotation-pdf`),
        method: 'GET',
        responseType: 'blob' // important
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `quotation-${this.orderID}-pdf.pdf`)
        document.body.appendChild(link)
        link.click()
        this.isSending = false
      })
    },
    sendDataLayer (actionName, paymentType = null) {
      try {
        const pkgType = this.packagingTypeId
        const csData = JSON.parse(this.totalResult.products[0].option_json)
        const smeData = JSON.parse(this.totalResult.products[0].sme_json)
        let dimension2 = ''
        if (this.totalResult.is_brief_design === 1) {
          dimension2 = 'จ้างออกแบบ'
        } else if (this.totalResult.design_by_yourself === 1) {
          dimension2 = 'ออกแบบออนไลน์'
        } else {
          dimension2 = 'ออกแบบเอง'
        }
        let dimension4 = ''
        const productData = this.totalResult.products_all[0].product
        if (productData.h) {
          dimension4 = `${productData.w / 10} x ${productData.l / 10} x ${productData.h / 10}`
        } else {
          dimension4 = `${productData.w / 10} x ${productData.l / 10}`
        }

        let productId = ''
        if (this.totalResult.products[0].mapping_input_id) {
          productId = String(this.totalResult.products[0].mapping_input_id).padStart(9, '0')
        } else {
          productId = 'Rigid' + String(this.totalResult.products[0].rigid_id).padStart(9, '0')
        }
        let productName = ''
        if (smeData) {
          productName = smeData.mapping_inputs.name_th
        } else {
          productName = this.totalResult.products[0].name
        }
        let gsm = ''
        if (csData && csData.cs_data && 'gsm' in csData.cs_data) {
          gsm = csData.cs_data.gsm
        } else if (csData && csData.cs_data && csData.cs_data.length > 0 && 'paper_gsm' in csData.cs_data) {
          gsm = csData.cs_data[0].paper_gsm + ''
        }
        let zipcode = ''
        if (csData && csData.cs_data && 'zipcode' in csData.cs_data) {
          zipcode = csData.cs_data.zipcode
        } else if (csData && csData.cs_data && csData.cs_data.length > 0 && 'zipcode' in csData.cs_data[0]) {
          zipcode = csData.cs_data[0].zipcode
        } else if ('zipcode' in this.totalResult) {
          zipcode = this.totalResult.zipcode
        }
        window.dataLayer.push({ ecommerce: null })
        window.dataLayer.push({
          event: actionName,
          ecommerce: {
            currency: 'THB',
            value: this.totalResult.total,
            payment_type: paymentType,
            items: [{
              item_id: `LOCOPACK_${pkgType}` + productId,
              item_name: productName,
              item_brand: 'Locopack',
              item_category: this.$pkgTypeThaiName(pkgType),
              dimension1: (typeof this.totalResult.products[0] !== 'undefined' ? 'โรงงาน ' + this.totalResult.products[0].factory_id : ''),
              dimension2,
              dimension3: (('mappingInput' in this.mappingInputObject) ? this.mappingInputObject.mappingInput.name_th : ''),
              dimension4,
              dimension5: ((smeData) ? smeData.cardPaperActiveName : ''),
              dimension6: gsm,
              dimension7: zipcode,
              dimension8: ((smeData && 'color' in smeData) ? this.colorText[pkgType][smeData.color] : ''),
              dimension10: '',
              quantity: this.totalResult.amount,
              price: (typeof this.totalResult.price_per_pcs !== 'undefined' ? this.totalResult.price_per_pcs : '')
            }]
          }
        })
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error)
      }
    },
    submitPurposes () {
      this.$refs.refSCGPConsent.submitPurposes()
    }
  }
  // head () {
  //   return {
  //     script: [
  //       {
  //         hid: 'My2c2p',
  //         src: ((process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'staging') ? 'https://t.2c2p.com/securepayment/api/my2c2p.1.6.9.min.js' : 'https://demo2.2c2p.com/2C2PFrontEnd/SecurePayment/api/my2c2p.1.6.9.min.js'),
  //         type: 'text/javascript',
  //         async: true,
  //         defer: true
  //       }
  //     ]
  //   }
  // }
}
