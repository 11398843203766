
import { mapMutations, mapState } from 'vuex'
export default {
  validate ({ params }) {
    // Must be a number
    return /^\d+$/.test(params.id)
  },
  async asyncData ({ params, $axios, $cookies }) {
    const result = await $axios.$get(`orders/${params.id}`)
    return {
      orderID: params.id,
      orderDetail: result.data
    }
  },
  data () {
    return {
      orderDetail: '',
      scb: require('~/static/images/SCB.jpg'),
      qrcode: require('~/static/images/qrcode.jpg')
    }
  },
  computed: {
    ...mapState(['userData'])
  },
  mounted () {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({ ecommerce: null })
    window.dataLayer.push({
      event: 'pageInfo',
      page: {
        type: 'purchase',
        path: `${this.$route.path}`,
        title: 'bank-transfer-finished'
      },
      user: {
        customerID: (this.userData) ? this.userData.uid : '',
        customerEmail: (this.userData) ? this.userData.email : ''
      }
    })
    this.hideLoading()
  },
  methods: {
    ...mapMutations(['showLoading', 'hideLoading'])
  }
}
